'use strict';

$(() => {
  const scroll = new SmoothScroll('.btn--anchor, .scroll-down', {
    speed: 300
  });

  const video = '.video__poster iframe';
  $('.video__poster').on('click', () => {
    $('.video__poster').addClass('is-play');
    $(video)[0].src += '?autoplay=1';
  });

  const slider = '.slider';
  const paginationSlider = '.slider__pagination';
  const btnInfo = '.slider__show-info';
  const sliderInfo = '.slider__info';
  const sliderInfoMessage = '.slider__info-message .text';

  $('.slider__list').on('init reinit beforeChange', (event, slick, currentSlide, nextSlide) => {
    const currentNum = nextSlide ? nextSlide : 0;
    const currentPagination = currentNum + 1;
    $(paginationSlider).text(currentPagination + '/' + slick.slideCount);
    $(btnInfo).attr('data-show', currentNum);
    const id = $(btnInfo).attr('data-show');
    $(sliderInfoMessage).each((i, item) => {
      $(item).attr('data-info', i);
    });
    const elem = '.slider__info-message .text[data-info="' + id + '"]';
    const textIsShow = '.text.is-show';
    $(textIsShow).removeClass('is-show');
    $(elem).addClass('is-show');

    if ($(btnInfo).hasClass('is-hide')) {
      $(btnInfo).removeClass('is-hide');
      $(btnInfo).text('скрыть информацию');
      $(sliderInfo).removeClass('is-hide');
      $(elem).addClass('is-show');
    }

    const images = slick.$slides.find('img')[currentNum];
    const imageSrc = $(images).attr('src');
    const newImageSrc = imageSrc.split('static/img/content/slider/')[1];
    $(slider).css('background-image', `url(static/img/content/slider/bg/${newImageSrc})`);
  });

  $('.slider__list').slick({
    adaptiveHeight: true,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        }
      }
    ]
  });

  const mediaWidth = window.matchMedia("(max-width: 1024px)");

  $(btnInfo).on('click', (e) => {
    $(e.target).toggleClass('is-hide');
    $(sliderInfo).toggleClass('is-hide');
    if(mediaWidth.matches) {
      $(e.target).hasClass('is-hide') ? $(e.target).text('скрыть информацию') : $(e.target).text('показать информацию');
      $('body').toggleClass('is-inactive');
    } else {
      $(e.target).hasClass('is-hide') ? $(e.target).text('показать информацию') : $(e.target).text('скрыть информацию');
    }
  });

  $('.slider__info .btn-close').on('click', () => {
    $(sliderInfo).addClass('is-hide');
    $(btnInfo).addClass('is-hide');
    $(btnInfo).hasClass('is-hide') ? $(btnInfo).text('показать информацию') : $(btnInfo).text('скрыть информацию');
  });

  const photoList = '.photo-list';
  const photoItem = '.photo-list__item';

  $('.slider__all-photos').on('click', () => {
    $(photoList).addClass('is-show');
    $('body').addClass('is-inactive');
  });
  
  $(photoItem).each((i, item) => {
    $(item).attr('data-photo', i);
    $(item).on('click', () => {
      $(photoList).removeClass('is-show');
      $('body').removeClass('is-inactive');
      
      const targetSlide = $(item).data('photo');
      $('.slider__list').slick('slickGoTo', targetSlide);
    });
  });

  $('.photo-list .btn-close').on('click', () => {
    $(photoList).removeClass('is-show');
    $('body').removeClass('is-inactive');
  });

  $(window).on("popstate", () => {
    if ($(photoList).hasClass('is-show')) {
      $(photoList).removeClass('is-show');
      $('body').removeClass('is-inactive');
    }

    if ($(sliderInfo).hasClass('is-hide')) {
      $(sliderInfo).removeClass('is-hide');
      $(btnInfo).removeClass('is-hide');
      $('body').removeClass('is-inactive');
    }
  });

  if (mediaWidth.matches) {
    $('.choose-galaxy__content').slick({
      adaptiveHeight: true,
      dots: false,
    });
  }

  const aboutTab = $('.about-project__pagination p');
  const aboutContent = $('.about-project__col');

  $(aboutTab).on('click', (e) => {
    const id = $(e.target).attr('data-text');
    const content = $('.about-project__col[data-text="' + id + '"]');
    
    const activeAboutTab = $(aboutTab, '.is-active');
    const activeAboutContent = $(aboutContent, '.is-active');

    $(activeAboutTab).removeClass('is-active');
    $(e.target).addClass('is-active');
    $(activeAboutContent).removeClass('is-active');
    $(content).addClass('is-active');
  });

  $('.social-list').socialLikes();
});
